<template>
  <b-modal
    @shown="updateItem"
    :title="`編輯${nowTableTitle}`"
    id="equipmentLocalPurchases"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <section class="search-block">
        <h5 class="big-title">
          {{ itemNow.BYear }}年度 第{{ nowSemester == 1 ? "一" : "二" }}學期
          培育學校:
          {{ itemNow.school }}
        </h5>
        <div class="dtc-search1">
          <b-button
            variant="warning"
            @click="openModalEquip(1)"
            size="sm"            
            ><i class="fas fa-plus"></i>新增</b-button
          >
          <!-- <b-button
            variant="primary"
            size="sm"
            :disabled="
              nowTab == 1 &&
              (itemNow.StatusName == '未預審' || itemNow.StatusName == '已退回')
                ? false
                : nowTab == 3
                ? false
                : true
            "
            >儲存</b-button
          > -->
          <b-button variant="danger" size="sm" @click="openPrintPage"
            >列印</b-button
          >
          <b-input-group prepend="衛生福利部補助金額上限" v-if="nowTab == 1">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee0"
              style="text-align: end;padding-right:5px;width:125px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group
            prepend="衛生福利部補助金額"
            v-if="nowTab == 1 || nowTab == 3"
          >
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee1"
              style="text-align: end;padding-right:5px;width:125px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="教學設備費採購總金額" v-if="nowTab == 3">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee2"
              style="text-align: end;padding-right:5px;width:125px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="剩餘金額" v-if="nowTab == 3">
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee3"
              style="text-align: end;padding-right:5px;width:125px"
              disabled
            ></vue-numeric>
          </b-input-group>
          <b-input-group
            prepend="學校自籌金額"
            v-if="nowTab == 1 || nowTab == 3"
          >
            <vue-numeric
              currency="NTD$"
              separator=","
              v-model="fee4"
              style="text-align: end;padding-right:5px;width:125px"
              disabled
            ></vue-numeric>
          </b-input-group>
        </div>
      </section>
      <div v-if="nowTab == 1">
        <header class="dtc-grid-header2 my-dark">
          <div
            v-for="(tabonehearItem, taboneIndex) in headers"
            :key="`taboneIndex${taboneIndex}`"
          >
            {{ tabonehearItem }}
          </div>
        </header>
        <main
          class="dtc-grid-header2"
          v-for="(item, i) in items"
          :key="i"
          style="color:#39312E;"
          :style="
            i % 2 == 0
              ? 'background-color:#ffffff ;'
              : 'background-color: #efefef;'
          "
        >
          <div style="padding-top:2px">
            <b-button
              class="mr-2"
              variant="primary"
              size="sm"
              style="height:32px;line-height:28px"
              @click="openModalEquip(2, item, i)"
              >編輯</b-button>
            <b-button
              variant="danger"
              size="sm"
              style="height:32px;line-height:28px"
              @click="deleteEquipFee(i)"
              >刪除</b-button>
          </div>
          <div style="padding-top:3px;">
            {{ i + 1 }}
          </div>
          <div :title="item.DeviceName">
            {{ item.DeviceName || "暫無資料" }}
          </div>
          <div :title="item.Quantity">
            {{ item.Quantity || "暫無資料" }}
          </div>
          <div :title="item.PriceFee" class="fee-content">
            {{ item.PriceFee ? $formatPrice(item.PriceFee) : 0 }}
          </div>
          <div :title="item.SubTotal" class="fee-content">
            {{ item.SubTotal ? $formatPrice(item.SubTotal) : 0 }}
          </div>
          <div :title="item.Remark">
            {{ item.Remark || "暫無資料" }}
          </div>
          <div :title="item.Purpose">{{ item.Purpose || "暫無資料" }}</div>
        </main>

        <footer class="dtc-grid-footer-tab1">
          <div>總計</div>
          <div class="fee-content" style="padding-right:7px">
            {{ $formatPrice(tabOneTotal) }}
          </div>
          <div></div>
        </footer>
      </div>
      <div v-if="nowTab == 3">
        <header class="dtc-grid-header my-dark">
          <div>操作</div>
          <div class="header-column2">
            <div class="header-column2-row1">
              <div class="header-column2-row1-word">採購設備費項目</div>
            </div>
            <div class="header-column2-row2">
              <div></div>
              <div></div>
              <div></div>
              <div>核銷金額</div>
            </div>
            <div class="header-column2-row3">
              <div style="border-top:0px;margin-top: -11px;">序</div>
              <div style="border-top:0px;margin-top: -11px;">設備名稱</div>
              <div style="border-top:0px;margin-top: -11px;">採購金額</div>
              <div class="two-col">
                <div
                  class="header-column2-end"
                  style="border-top:0px;border-right:1px solid #cccccc;"
                >
                  衛生服務部補助款
                </div>
                <div class="header-column2-end" style="border-top:0px">
                  學校自籌款
                </div>
              </div>
            </div>
          </div>
          <div>備註</div>
          <div>用途說明</div>
        </header>
        <main
          class="dtc-grid-header2"
          v-for="(item, i) in items"
          :key="i"
          style="color:#39312E;"
          :style="
            i % 2 == 0
              ? 'background-color:#ffffff ;'
              : 'background-color: #efefef;'
          "
        >
          <div style="padding-top:2px">
            <b-button
              class="mr-2"
              variant="primary"
              size="sm"
              style="height:32px;line-height:28px"
              @click="openModalEquip(2, item, i)"
              >編輯</b-button
            >
            <b-button
              variant="danger"
              size="sm"
              style="height:32px;line-height:28px"
              @click="deleteEquipFee(i)"
              >刪除</b-button
            >
          </div>
          <div style="cursor: pointer;padding-top:3px;">
            {{ i + 1 }}
          </div>
          <div :title="item.DeviceName">
            {{ item.DeviceName || "暫無資料" }}
          </div>
          <div :title="item.Apply_Fee" class="fee-content">
            {{ item.Apply_Fee ? $formatPrice(item.Apply_Fee) : 0 }}
          </div>
          <div :title="item.Approved_Fee" class="fee-content">
            {{ item.Approved_Fee ? $formatPrice(item.Approved_Fee) : 0 }}
          </div>
          <div :title="item.School_Fee" class="fee-content">
            {{ item.School_Fee ? $formatPrice(item.School_Fee) : 0 }}
          </div>
          <div :title="item.Remark">{{ item.Remark || "暫無資料" }}</div>
          <div :title="item.Purpose">{{ item.Purpose || "暫無資料" }}</div>
        </main>
        <footer class="dtc-grid-footer">
          <div>總計</div>
          <div class="fee-content" style="padding-right:5.5px">
            {{ $formatPrice(tab2Total1) }}
          </div>
          <div class="fee-content" style="padding-right:5.5px">
            {{ $formatPrice(tab2Total2) }}
          </div>
          <div class="fee-content" style="padding-right:5.5px">
            {{ $formatPrice(tab2Total3) }}
          </div>
          <div></div>
        </footer>
      </div>
      <div v-if="nowTab == 1">
        <b-modal
          class="modalEquipItem"
          id="modalEquip"
          :title="`${modalType == 1 ? '新增' : '編輯'}設備費採購品目`"
          size="md"
          :header-bg-variant="headerBgVariant"
        >
          <div class="equip-grid-2">
            <b-input-group prepend="設備名稱">
              <b-input
                type="text"
                v-model="modalEquipNameTab1"
                style="text-align: end;padding-right:20px"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="設備數量">
              <FormatPriceInput
                style="width: 377px;"
                :data="modalEquipNumberTab1"
                keyname="modalEquipNumberTab1"
                maxdata="999999"
                @update="saveSingleData"
                v-model="modalEquipNumberTab1"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="設備單價">
              <FormatPriceInput
                style="width: 377px;"
                :data="modalEquipPriceTab1"
                keyname="modalEquipPriceTab1"
                maxdata="5000000"
                @update="saveSingleData"
                v-model="modalEquipPriceTab1"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="經費來源">
              <b-input
                type="text"
                v-model="modalFeeOriginTab1"
                style="text-align: end;padding-right:20px"
                placeholder="上限80字"
                maxlength="80"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="用途說明">
              <b-input
                type="text"
                v-model="modalFeeIlluTab1"
                style="text-align: end;padding-right:20px"
                placeholder="上限80字"
                maxlength="80"
              ></b-input>
            </b-input-group>
            <div class="pt-2 mt-2 text-danger">請確認該設備係用於教學研究之用</div>
          </div>
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                class="float-right mr-1"
                @click="$bvModal.hide('modalEquip')"
                >取消</b-button
              >
              <b-button
                variant="primary"
                class="float-right mr-1"
                @click="saveEquipFee"
                >確定</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
      <div v-if="nowTab == 3">
        <b-modal
          class="modalEquipItem"
          id="modalEquip"
          :title="`${modalType == 1 ? '新增' : '編輯'}設備費採購品目`"
          size="md"
          :header-bg-variant="headerBgVariant"
        >
          <div class="equip-grid-2">
            <b-input-group prepend="設備名稱">
              <b-input
                type="text"
                v-model="modalEquipName"
                style="text-align: end;padding-right:20px"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="採購金額">
              <FormatPriceInput
                style="width: 377px;"
                :data="modalBuyPrice"
                keyname="modalBuyPrice"
                maxdata="999999"
                @update="saveSingleData"
                v-model="modalBuyPrice"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="補助金額">
              <FormatPriceInput
                style="width: 377px;"
                :data="modalSubsidyPrice"
                keyname="modalSubsidyPrice"
                maxdata="999999"
                @update="saveSingleData"
                v-model="modalSubsidyPrice"
                isSingleCheck="true"
              ></FormatPriceInput>
            </b-input-group>
            <b-input-group prepend="自籌金額">
              <b-input
                v-model="modalSelfPrice"
                disabled
                style="text-align: right;"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="備註內容">
              <b-input
                type="text"
                v-model="modalFeeRemark"
                style="text-align: end;padding-right:20px"
                placeholder="上限20字"
                maxlength="20"
              ></b-input>
            </b-input-group>
            <b-input-group prepend="用途說明">
              <b-input
                type="text"
                v-model="modalFeeIllu"
                style="text-align: end;padding-right:20px"
                placeholder="上限80字"
                maxlength="80"
              ></b-input>
            </b-input-group>
          </div>
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                class="float-right mr-1"
                @click="$bvModal.hide('modalEquip')"
                >取消</b-button
              >
              <b-button
                variant="primary"
                class="float-right mr-1"
                @click="saveEquipFee"
                >確定</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('equipmentLocalPurchases')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import FormatPriceInput from "./FormatPriceInput";

const headers = [
  "操作",
  "序",
  "設備名稱",
  "數量",
  "單價",
  "合計",
  "經費來源",
  "用途說明",
];
export default {
  data() {
    return {
      itemNow: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],

      headers,

      headerBgVariant: "warning",
      modalEnoTab2: -1,
      modalPlaceTab2: -1,
      modalEquipName: "",
      modalBuyPrice: 0,
      modalSelfPrice: 0,
      modalSubsidyPrice: 0,
      modalFeeRemark: "",
      modalFeeIllu: "",

      fee0: 0,
      fee1: 0,
      fee2: 0,
      fee3: 0,
      fee4: 0,

      modalType: 1, //1:新增 2 編輯

      modalEnoTab1: -1,
      modalPlaceTab1: -1,
      modalEquipNameTab1: "",
      modalEquipNumberTab1: 0,
      modalEquipPriceTab1: 0,
      modalFeeOriginTab1: "",
      modalFeeIlluTab1: "",
      modalObj: {},

      tabOneTotal: 0,
      tab2Total1: 0,
      tab2Total2: 0,
      tab2Total3: 0,

      seqNumber: -1,
      items: [],
    };
  },
  components: { FormatPriceInput },
  computed: {
    nowTableTitle() {
      return store.nowTableTitle;
    },
    nowTab() {
      return store.nowTab;
    },
    nowSemester() {
      return store.semester;
    },
    nowStatus(){return store.editItem.Status}
  },
  methods: {
    saveSingleData(obj) {
      if (obj.isUp) {
        this.$bvToast.toast(`超過上限值，系統將自動帶入上限值`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      this[`${obj.keyname}`] = obj.data;

      this.modalSelfPrice =
        +this.modalBuyPrice - +this.modalSubsidyPrice > 0
          ? +this.modalBuyPrice - +this.modalSubsidyPrice
          : 0;
    },
    openModalEquip(key, item, place) {
      this.modalType = key;
      if (key == 1) {
        // requestAnimationFrame(() => {
        this.modalEnoTab1 = -1;
        this.modalPlaceTab1 = -1;
        this.modalEquipNameTab1 = "";
        this.modalEquipNumberTab1 = 0;
        this.modalEquipPriceTab1 = 0;
        this.modalFeeOriginTab1 = "";
        this.modalFeeIlluTab1 = "";

        this.modalEnoTab2 = -1;
        this.modalPlaceTab2 = -1;
        this.modalEquipName = "";
        this.modalBuyPrice = 0;
        this.modalSelfPrice = 0;
        this.modalSubsidyPrice = 0;
        this.modalFeeRemark = "";
        this.modalFeeIllu = "";
        // });
      } else if (key == 2 && this.nowTab == 1) {
        let obj = {};
        obj = { ...item };
        // requestAnimationFrame(() => {
        this.modalPlaceTab1 = place;
        this.modalEquipNameTab1 = obj.DeviceName;
        this.modalFeeOriginTab1 = obj.Remark;
        this.modalFeeIlluTab1 = obj.Purpose;
        this.modalEquipNumberTab1 = +obj.Quantity;
        this.modalEquipPriceTab1 = +obj.PriceFee;
        this.modalEnoTab1 = obj.Eno;
        // });
      } else if (key == 2 && this.nowTab == 3) {
        let obj = {};
        obj = { ...item };
        requestAnimationFrame(() => {
          // this.$refs.modalBuyPrice.amount = obj.Apply_Fee;
          this.modalEquipName = obj.DeviceName;
          this.modalBuyPrice = obj.Apply_Fee;
          this.modalSubsidyPrice = obj.Approved_Fee;
          this.modalFeeRemark = obj.Remark;
          this.modalFeeIllu = obj.Purpose;
          this.modalPlaceTab2 = place;
          this.modalEnoTab2 = obj.Eno;
          this.modalSelfPrice =
            +this.modalBuyPrice - +this.modalSubsidyPrice > 0
              ? +this.modalBuyPrice - +this.modalSubsidyPrice
              : 0;
        });
      }
      this.$bvModal.show("modalEquip");
    },
    async saveEquipFee() {
      let arr = [...this.items];
      let objItem = {};
      if (this.nowTab == 1) {
        objItem = {
          DeviceName: this.modalEquipNameTab1,
          Quantity: this.modalEquipNumberTab1,
          PriceFee: this.modalEquipPriceTab1,
          SubTotal: +this.modalEquipNumberTab1 * +this.modalEquipPriceTab1,
          Remark: this.modalFeeOriginTab1,
          Purpose: this.modalFeeIlluTab1,
        };
      } else if (this.nowTab == 3) {
        objItem = {
          DeviceName: this.modalEquipName,
          Apply_Fee: this.modalBuyPrice,
          Approved_Fee: this.modalSubsidyPrice,
          School_Fee: this.modalSelfPrice,
          Remark: this.modalFeeRemark,
          Purpose: this.modalFeeIllu,
        };
      }

      if (this.modalType == 2 && this.nowTab == 1) {
        objItem.Eno = this.modalEnoTab1;
        arr.splice(this.modalPlaceTab1, 1, objItem);
      } else if (this.modalType == 2 && this.nowTab == 3) {
        objItem.Eno = this.modalEnoTab2;
        arr.splice(this.modalPlaceTab2, 1, objItem);
      } else {
        arr.push(objItem);
      }
      if (this.nowTab == 3) {
        const res = this.checkApprovedFee(arr);
        if (res > 0) {
          this.modalSubsidyPrice = +this.modalSubsidyPrice - res;
          this.$bvToast.toast(
            `總補助金額請勿超過上限值，超過將自動帶入上限值`,
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }
      }
      const obj = {
        SeqNo: this.seqNumber,
        Equipments: [...arr],
      };

      try {
        let url = "";
        if (this.nowTab == 1) {
          url = "Budgeting/SetDevices";
        } else if (this.nowTab == 3) {
          url = "Equipment/SetDevices";
        }

        await axios.put(url, obj);
        this.$bvToast.toast(`${this.modalType == 1 ? "新增" : "編輯"}成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
        this.$bvModal.hide("modalEquip");
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteEquipFee(key) {
      let arr = [...this.items];
      arr.splice(key, 1);
      const obj = {
        SeqNo: this.seqNumber,
        Equipments: [...arr],
      };
      try {
        let url = "";
        if (this.nowTab == 1) {
          url = "Budgeting/SetDevices";
        } else if (this.nowTab == 3) {
          url = "Equipment/SetDevices";
        }
        await axios.put(url, obj);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    doSometing() {
      //   this.$emit("updateRuralProgress");
    },
    updateItem() {
      this.items = [];
      this.modalBuyPrice = 0;

      this.fee1 = 0;
      this.fee2 = 0;
      this.fee3 = 0;
      this.fee4 = 0;
      this.modalType = 1;
      this.modalEnoTab1 = -1;
      this.modalPlaceTab1 = -1;
      this.modalEquipNameTab1 = "";
      this.modalEquipNumberTab1 = 0;
      this.modalEquipPriceTab1 = 0;
      this.modalFeeOriginTab1 = "";
      this.modalFeeIlluTab1 = "";
      this.modalObj = {};
      this.tabOneTotal = 0;
      this.tab2Total1 = 0;
      this.tab2Total2 = 0;
      this.tab2Total3 = 0;
      this.seqNumber = -1;
      this.itemNow = { ...store.editItem };
      this.fee0 = this.itemNow.Apply_Device_Amt;

      this.getData();
    },
    async getData() {
      let url = "";
      if (this.nowTab == 1) {
        url = `Budgeting/GetDeviceList?byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&schoolcode=${this.itemNow.SchoolCode}&category=S`;
      } else if (this.nowTab == 3) {
        url = `Equipment/GetEquipmentHead?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=S`;
      }
      try {
        if (this.nowTab == 1) {
          const { SeqNo, equipment_detail, Device_Amount } = await axios.get(
            url
          );
          this.items = [...equipment_detail];
          this.tabOneTotal = 0;
          this.seqNumber = SeqNo;
          this.items.forEach((s) => {
            this.tabOneTotal += s.SubTotal;
          });
          this.fee1 = Device_Amount;
          this.fee4 = +this.tabOneTotal - +Device_Amount;
        } else {
          const {
            SeqNo,
            equipments,
            DeviceCost,
            DeviceSelf,
            DeviceTotal,
            DeviceSurplus,
          } = await axios.get(url);

          this.items = [...equipments];
          this.seqNumber = SeqNo;
          this.tab2Total1 = 0;
          this.tab2Total2 = 0;
          this.tab2Total3 = 0;
          this.items.forEach((s) => {
            this.tab2Total1 += s.Apply_Fee;
            this.tab2Total2 += s.Approved_Fee;
            this.tab2Total3 += s.School_Fee;
          });
          this.fee1 = DeviceTotal!=null?DeviceTotal:0;
          this.fee2 = this.tab2Total1;
          console.log(this.fee2);//wawa
          // this.fee3 = DeviceSurplus;
          // this.fee4 = DeviceSelf;

          this.fee3 = +this.fee1 - +this.fee2 > 0 ? +this.fee1 - +this.fee2 : 0;
          this.fee4 = +this.fee2 - +this.fee1 > 0 ? +this.fee2 - +this.fee1 : 0;
        }
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openPrintPage() {
      let url = "";
      if (this.nowTab == 1) {
        url = `/#/doc15?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=S`;
      } else if (this.nowTab == 3) {
        url = `/#/doc6?schoolcode=${this.itemNow.SchoolCode}&byear=${this.itemNow.BYear}&term=${this.itemNow.Term}&category=S`;
      }
      window.open(url);
    },
    checkApprovedFee(arr) {
      let nowTypeApprovedTotal = 0;
      arr.forEach((s) => {
        nowTypeApprovedTotal += s.Approved_Fee;
      });
      const difference = +nowTypeApprovedTotal - +this.fee1;
      return difference;
    },
  },
  mounted() {
    //console.log(store)
  },
  watch: {
    modalBuyPrice(v) {
      // console.log(" modalBuyPrice", v);
      requestAnimationFrame(() => {
        this.modalSelfPrice =
          +this.modalBuyPrice - +this.modalSubsidyPrice > 0
            ? +this.modalBuyPrice - +this.modalSubsidyPrice
            : 0;
      });
    },
    modalSubsidyPrice(v) {
      requestAnimationFrame(() => {
        this.modalSelfPrice =
          +this.modalBuyPrice - +this.modalSubsidyPrice > 0
            ? +this.modalBuyPrice - +this.modalSubsidyPrice
            : 0;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.input-group {
  margin-bottom: 10px;
}

.dtc-grid-header {
  display: grid;
  grid-template-columns: 120px 830px 156px 230px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #cccccc;
  border-bottom: 0px;
  margin-right: 4px;
  color: #0757a7;
  > div {
    border-right: 1px solid #cccccc;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    height: 120px;
    padding-top: 38px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column2 {
    padding-top: 0px;

    .header-column2-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column2-row2 {
      display: grid;
      grid-template-columns: 70px 250px 170px 340px;
      > div {
        border-right: 1px solid #cccccc;
        border-top: 1px solid #cccccc;
        height: 31px;
      }
    }
    .header-column2-row3 {
      display: grid;
      grid-template-columns: 70px 250px 170px 340px;
      font-size: 13px;
      > div {
        border-right: 1px solid #cccccc;
        border-top: 1px solid #cccccc;
        height: 68px;
      }
      .two-col {
        display: grid;
        grid-template-columns: 170px 170px;
        .header-column2-end {
          font-size: 14px;
          padding-top: 6px;
        }
      }
    }
  }
}
.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-grid-header2,
.dtc-grid-footer,
.dtc-grid-footer-tab1 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 120px 70px 250px 170px 170px 170px 156px 231px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}
.dtc-grid-footer-tab1 {
  grid-template-columns: 780px 170px 387px;
  > div {
    font-size: 16px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -122px;
  width: 123%;
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(2, 60px) repeat(10, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
.btn-sm,
.btn-group-sm > .btn {
  height: 37px;
}
.input-group-text {
  background: #0379fd;
  color: #fff;
}

div.number > div > div.input-group-text {
  width: 60px;
  background: #0379fd;
  color: #fff;
}
.dtc-grid-footer {
  grid-template-columns: 440px 170px 170px 170px 387px;
  > div {
    font-weight: 600;
    font-size: 16px;
  }
}
.fee-content {
  text-align: right;
}
.avoid-clicks {
  pointer-events: none;
}
</style>
