<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-各科系註冊費設定
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
            :disabled="healthCodeCheck"
          ></b-select>
        </b-input-group>
        <!-- <b-button variant="primary" @click="searchDb"></b-button> -->
        <!--  <b-button variant="danger" @click="clearSearch">清除條件</b-button> -->
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div :title="item.servicenow" style="padding:2px 0">
        <b-button variant="success" size="sm" @click="openModal(item)"
          >編輯</b-button
        >
      </div>
      <div style="cursor: pointer;padding-top:3px;">
        {{ +i + 1 }}
      </div>
      <div :title="item.school">
        {{ item.school || "" }}
      </div>
      <div :title="item.majorSys">
        {{ item.majorSys || "" }}
      </div>
      <div :title="item.MajorName">{{ item.MajorName || "" }}</div>
      <div :title="item.Fee1">
        {{ item.Fee1 ? $formatPrice(item.Fee1) : "0" }}
      </div>
      <div :title="item.Fee2">
        {{ item.Fee2 ? $formatPrice(item.Fee2) : "0" }}
      </div>
      <div :title="item.Fee3">
        {{ item.Fee3 ? $formatPrice(item.Fee3) : "0" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal id="editModal" title="編輯各科系註冊費" size="md">
        <div class="equip-grid-2">
          <b-input-group prepend="學校名稱">
            <b-input
              v-model="schoolName"
              disabled
              style="text-align:center;padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="系組別" class="mt-2">
            <b-input
              v-model="departmentGroup"
              disabled
              style="text-align:center;padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="系組名稱" class="mt-2">
            <!-- <b-select
              :options="departmentOptions"
              v-model="departmentName"
              disabled
            ></b-select> -->
            <b-input
              v-model="departmentName"
              disabled
              style="text-align:center;padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="學雜費" class="mt-2">
            <FormatPriceInput
              style="width: 341px;"
              :data="tuitionFee"
              keyname="tuitionFee"
              maxdata="99999"
              @update="saveSingleData"
              v-model="tuitionFee"
              isSingleCheck="true"
            ></FormatPriceInput>
          </b-input-group>
          <b-input-group prepend="保險費" class="mt-2">
            <FormatPriceInput
              style="width: 341px;"
              :data="insuranceFee"
              keyname="insuranceFee"
              maxdata="99999"
              @update="saveSingleData"
              v-model="insuranceFee"
              isSingleCheck="true"
            ></FormatPriceInput>
          </b-input-group>
          <b-input-group prepend="其他相關費用" class="mt-2">
            <FormatPriceInput
              style="width: 341px;"
              :data="otherFee"
              keyname="otherFee"
              maxdata="99999"
              @update="saveSingleData"
              v-model="otherFee"
              isSingleCheck="true"
            ></FormatPriceInput>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveFee"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import FormatPriceInput from "./FormatPriceInput";

// import { countryObject } from "@/constant.js";

const headersOne = [
  { name: "操作", key: "", sortDesc: null },
  { name: "序", key: "", sortDesc: null },
  { name: "培育學校", key: "SchoolCode", sortDesc: null },
  { name: "系組別", key: "MajorSysCodeDisplay", sortDesc: null },
  { name: "系組名稱", key: "MajorName", sortDesc: null },
  { name: "學雜費", key: "Fee1", sortDesc: null },
  { name: "保險費", key: "Fee2", sortDesc: null },
  { name: "其他相關費用", key: "Fee3", sortDesc: null },
];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1906;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headersOne,

      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      majorObject: [],
      searchSchool: null,

      schoolName: "",
      departmentGroup: "",
      departmentName: "",
      departmentId: "",
      departmentOptions: [],

      schoolCodeNow: "",

      tuitionFee: 0,
      insuranceFee: 0,
      otherFee: 0,
      editObj: {},
    };
  },
  components: { FormatPriceInput },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },

  methods: {
    saveSingleData(obj) {
      if (obj.isUp) {
        this.$bvToast.toast(`超過上限值，系統將自動帶入上限值`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      this[`${obj.keyname}`] = obj.data;
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(item) {
      this.departmentName = "";
      if (this.schoolCodeNow != item.SchoolCode) {
        await this.getDepartment(item.SchoolCode);
      }

      this.schoolCodeNow = item.SchoolCode;
      this.schoolName = item.school;
      this.departmentGroup = item.MajorSysCodeDisplay;
      this.departmentName = item.MajorName;
      this.departmentId = item.MajorId;
      this.tuitionFee = item.Fee1;
      this.insuranceFee = item.Fee2;
      this.otherFee = item.Fee3;
      this.editObj = { ...item };
      this.$bvModal.show("editModal");
    },
    async saveFee() {
      const url = `api/MajorBudget`;

      const obj = {
        SchoolCode: this.editObj.SchoolCode,
        MajorId: this.departmentId,
        MajorName: this.departmentName,
        SchoolName: this.schoolName,
        MajorSysCodeDisplay: this.departmentGroup,
        Fee1: this.tuitionFee,
        Fee2: this.insuranceFee,
        Fee3: this.otherFee,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`註冊費設定編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchName = "";

      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      // if (this.searchName) {
      //   qs = this.$appendSubstringofFilter("Name", qs, this.searchName);
      // }
      // if (this.searchId) {
      //   qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
      // }
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }

      if (this.searchSchool) {
        qs = this.$equalFilter("SchoolCode", qs, this.searchSchool);
      }

      try {
        let { Items, Count } = await window.axios.get(`MajorBudget/List${qs}`);

        Items = Items.map((s) => {
          s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "";
          s.majorSys = this.majorObject.find((k) => k.value == s.MajorId)
            ? this.majorObject.find((k) => k.value == s.MajorId).text
            : "";
          return s;
        });
        this.items = { ...Items };
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
    async getMajor() {
      const arr = await window.axios.get("SysCode/Get?category=major");
      this.majorObject = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));
    },
    async getDepartment(schoolCode) {
      const arr = await window.axios.get(`Major/Get?schoolcode=${schoolCode}`);
      this.departmentOptions = arr.map((s) => ({
        value: s.MajorName,
        text: s.MajorName,
        year: s.MajorYear,
        id: s.MajorId,
      }));
    },
  },
  async mounted() {
    store.nowTab = 3;
    this.getAllSchool();
    this.getMajor();
    this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    departmentName(v) {
      if (v) {
        const majorId = this.departmentOptions.find((s) => s.text == v)
          ? this.departmentOptions.find((s) => s.text == v).id
          : "";
        this.departmentId = majorId;

        this.departmentGroup = this.majorObject.find((k) => k.value == majorId)
          ? this.majorObject.find((k) => k.value == majorId).text
          : "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 90px 90px 260px 210px 250px 220px 220px 220px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
</style>
