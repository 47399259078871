<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-預審核定清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="年度">
          <b-select
            :options="years"
            v-model="searchYear"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="學期">
          <b-select
            :options="[
              { value: 1, text: '第一學期(上學期)' },
              { value: 2, text: '第二學期(下學期)' },
            ]"
            v-model="searchSemester"
            @change="searchDb"
          ></b-select>
        </b-input-group>

        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>
    <!-- <b-button variant="success" @click="batchPass" class="mt-1 mb-3"
      >批次核定</b-button
    > -->
    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color: #39312e"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="cursor: pointer; padding-top: 3px">
        {{ i + 1 }}
      </div>
      <!-- <div :title="item.status" style="padding:2px 0">
        <input
          type="checkbox"
          class="mr-2"
          v-model="item.approved"
          :disabled="item.StatusName != '申請中' ? true : false"
        />
        <b-button
          variant="success"
          size="sm"
          @click="assessPass(item)"
          :disabled="item.StatusName != '申請中' ? true : false"
          >通過</b-button
        >
      </div> -->

      <!--<div :title="item.servicenow" style="padding: 2px 0">
        <b-button variant="primary" size="sm" @click="openPrintPage(item)"
          >檢視</b-button
        >
      </div>-->
      <div style="padding: 2px 0" v-if="searchSemester == 1">
        <b-button
          variant="primary"
          size="sm"
          @click="openPrintPage(item)"
          v-if="item.Status >= 4" 
          >檢視</b-button
        >
        <b-button
          variant="success"
          size="sm"
          @click="openEquipPurchase(item)"
          v-else
          >編輯</b-button
        >
      </div>

      <div :title="item.name" style="padding: 2px 0">
        <b-button
          variant="primary"
          size="sm"
          @click="openPrintPage2(item)"
          :disabled="searchSemester == 2"
          >檢視</b-button
        >
      </div>
      <div style="padding: 2px 0">
        <b-button block
          v-if="item.Status == 6"
          variant="warning"
          size="sm"
          @click="assessPass(item)"
          :id="`itemOne${i}`"
          >確認資料</b-button
        >
        <b-button
          v-else block
          variant="warning"
          size="sm"
          :id="`itemOne${i}`"
          :disabled="true"
          >已確認</b-button
        >
      </div>
      <b-tooltip
        :target="`itemOne${i}`"
        placement="top"
        :variant="item.StatusName == '完成核定' ? 'primary' : 'danger'"
        style="text-align: left; min-width: 300px"
        v-if="item.StatusName == '完成核定' || item.StatusName == '已退回'"
      >
        <div v-if="item.StatusName == '完成核定'" style="text-align: left">
          完成核定<br />請於{{
            item.PerAuditComment ? $twDate4(item.PerAuditComment) : ""
          }}前函文
        </div>
        <div v-else style="text-align: left">
          退回申請<br />原因:{{ item.RejectComment || "" }}
        </div>
      </b-tooltip>
      <div>{{ item.ApplyDate ? $twDate(item.ApplyDate) : "" }}</div>
      <div>{{ item.ApplyNumber || "" }}</div>
      <div :title="item.school">{{ item.school || "" }}</div>
      <div :title="item.BYear">{{ item.BYear || "" }}</div>
      <div :title="item.Term">{{ item.Term == 1 ? "一" : item.Term == 2 ? "二" : "" }}</div>
      <div :title="item.Subsidy_Amt">{{ item.Subsidy_Amt ? $formatPrice(item.Subsidy_Amt) : "" }}</div>
      <div :title="item.Apply_Amt">{{ item.Apply_Amt ? $formatPrice(item.Apply_Amt) : "" }}</div>
      <div v-if="item.Status == 6">
        <div>
          {{ item.Approve_Amt ? $formatPrice(item.Approve_Amt) : "" }}
        </div>
      </div>
      <div style="padding: 0; overflow: initial" v-else>
        <div>
          <b-input
            type="number"
            v-model="item.Approve_Amt"
            @change="saveApproveData3(item)"
            style="height: 40px; text-align: center"
          />
        </div>
      </div>
      <div v-if="item.Status == 6">
        <div>
          {{ item.ApproveDate ? $twDate(item.ApproveDate) : "" }}
        </div>
      </div>
      <div v-else style="padding: 0; overflow: initial">
        <calendarInput
          v-model="item.ApproveDate"
          style="height: 40px; text-align: center"
          :nowDataIndex="`${i}`"
          @update2="saveApproveData"
          :ref="`calendarInput${item.SeqNo}`"
          :key="`calendarInput${i}${compontKey}`"
        />
      </div>

      <div v-if="item.Status == 6">
        <div>{{ item.ApproveNumber || "" }}</div>
      </div>
      <div v-else style="padding: 0; overflow: initial">
        <b-input
          v-model.trim="item.ApproveNumber"
          placeholder="核定文號"
          @change="saveApproveData2(item)"
          style="text-align: center; height: 40px"
        ></b-input>
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editMultilpleSchoolModal"
        title="批次核定"
        size="md"
        header-bg-variant="success"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="培育學校">
            <b-textarea
              v-model="passMultiSchoolName"
              no-auto-shrink
              disabled
            ></b-textarea>
          </b-input-group>
          <b-input-group prepend="核定日期" class="mt-2">
            <!-- <b-input v-model="passOneSchoolDate" disabled></b-input> -->
            <date-picker-tw
              :time="passOneSchoolDate"
              @update="(t) => (passOneSchoolDate = t)"
            ></date-picker-tw>
          </b-input-group>
          <b-input-group prepend="核定文號" class="mt-2">
            <b-input
              v-model="passNumber"
              placeholder="請在此輸入核定文號"
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editMultilpleSchoolModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="passMultiSchool"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="editOneSchoolModal"
        title="核定設定"
        size="md"
        header-bg-variant="success"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="培育學校">
            <b-input v-model="passOneSchoolName" disabled></b-input>
          </b-input-group>
          <b-input-group prepend="核定日期" class="mt-2">
            <!-- <b-input v-model="passOneSchoolDate" disabled></b-input> -->
            <date-picker-tw
              :time="passOneSchoolDate"
              @update="(t) => (passOneSchoolDate = t)"
            ></date-picker-tw>
          </b-input-group>
          <b-input-group prepend="核定文號" class="mt-2">
            <b-input
              v-model="passOneSchoolApprovedNumber"
              placeholder="請在此輸入核定文號"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="請款金額" class="mt-2">
          <b-input
            type="number"
            v-model="passOneSchoolApprove_Amt"
          />
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editOneSchoolModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="passOneSchool"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import calendarInput from "@/components/localCalendar.vue";

const headersOne = [
  { name: "序", key: "", sortDesc: null },
  { name: "待遇補助經費申請表", key: "", sortDesc: null },
  { name: "教材設備費", key: "", sortDesc: null },
  { name: "審核狀態", key: "Status", sortDesc: null },
  { name: "學校發文日期", key: "ApplyDate", sortDesc: null },
  { name: "學校發文文號", key: "ApplyNumber", sortDesc: null },
  { name: "培育學校", key: "SchoolCode", sortDesc: null },
  { name: "年度", key: "BYear", sortDesc: null },
  { name: "學期", key: "Term", sortDesc: null },
  { name: "核定金額", key: "Approve_Amt", sortDesc: null },
  { name: "請領(撥付)金額", key: "", sortDesc: null },
  { name: "請款(撥付)金額", key: "Apply_Amt", sortDesc: null },
  { name: "衛福部撥付日期", key: "ApproveDate", sortDesc: null },
  { name: "衛福部撥付文號", key: "ApproveNumber", sortDesc: null },
];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1905;
let years = new Array(9).fill().map((s, i) => year + 1 - i);

export default {
  name: "downarea",
  data() {
    return {
      headersOne,

      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      searchSchool: null,
      searchYear: new Date().getFullYear() - 1911,
      searchSemester: 1,

      passMultiSchoolName: "",
      passMultiSeq: "",
      passNumber: "",

      passOneSchoolName: "",
      passOneSchoolApprovedNumber: "",
      passOneSchoolSeqNumber: "",
      passOneSchoolDate: "",
      passOneSchoolApprove_Amt: 0,
      compontKey: 0,
    };
  },
  components: { calendarInput },
  computed: {},

  methods: {
    async saveApproveData(item) {
      const obj = {
        ApproveNumber: this.items[item.index].ApproveNumber,
        Content: [
          {
            ApproveDate: this.$moment(item.data).format("YYYY-MM-DD"),
            SeqNo: this.items[item.index].SeqNo,
          },
        ],
      };
      const url = `BudgetApply/SetApproveData`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`核定日期儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async saveApproveData2(item) {
      const obj = {
        ApproveNumber: item.ApproveNumber,
        Content: [
          {
            ApproveDate: item.ApproveDate,
            SeqNo: item.SeqNo,
          },
        ],
      };
      const url = `BudgetApply/SetApproveData`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`核定文號儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async saveApproveData3(item) {
      const obj = {
        ApproveNumber: item.ApproveNumber,
        Content: [
          {
            ApproveDate: item.ApproveDate,
            Approve_Amt:item.Approve_Amt,
            SeqNo: item.SeqNo,
          },
        ],
      };
      const url = `BudgetApply/SetApproveData`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`請款(撥付)金額儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async assessPass(item) {
      this.passOneSchoolName = item.school;
      this.passOneSchoolApprovedNumber = "";
      this.passOneSchoolSeqNumber = item.SeqNo;
      this.passOneSchoolApprove_Amt = item.Apply_Amt;
      this.passOneSchoolDate = this.$twDate2( this.$moment(new Date()).format("YYYY-MM-DD"));

      this.$bvModal.show("editOneSchoolModal");
    },
    async passOneSchool() {
      if (!Boolean(this.passOneSchoolApprovedNumber)) {
        this.$bvToast.toast(`請輸入核定文號`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      const url = `BudgetApply/Approve`;
      const obj = {
        ApproveNumber: this.passOneSchoolApprovedNumber,
        Content: [
          {
            ApproveDate: this.$usDate(this.passOneSchoolDate),
            Approve_Amt: this.passOneSchoolApprove_Amt,
            SeqNo: this.passOneSchoolSeqNumber,
          },
        ],
      };
      try {
        // this.$refs[
        //   `calendarInput${this.passOneSchoolSeqNumber}`
        // ][0].currentTime = this.$moment(new Date()).format;
        
        await window.axios.put(url, obj);

        this.$bvToast.toast("核定成功", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        await this.getData();

        this.$bvModal.hide("editOneSchoolModal");
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    batchPass() {
      const arr = this.items.filter((s) => s.approved);

      if (!arr.length) {
        this.$bvToast.toast("請勾選所需核定學校", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      const school = arr
        .reduce((acc, cur) => acc + "," + cur.school, "")
        .substring(1);
      const seq = arr
        .reduce((acc, cur) => acc + "," + cur.SeqNo, "")
        .substring(1);
      this.passNumber = "";
      this.passMultiSchoolName = school;
      this.passMultiSeq = seq;
      this.passOneSchoolDate = this.$twDate2(
        this.$moment(new Date()).format("YYYY-MM-DD")
      );

      this.$bvModal.show("editMultilpleSchoolModal");
    },
    async passMultiSchool() {
      if (!Boolean(this.passNumber)) {
        this.$bvToast.toast(`請輸入核定文號`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      const url = `BudgetApply/Approve`;
      const seqArr = this.passMultiSeq.split(",");

      const seqNosArray = seqArr.map((s) => ({
        ApproveDate: this.$usDate(this.passOneSchoolDate),
        SeqNo: s,
      }));
      const refKeyArray = seqArr.map((s) => ({
        refKey: `calendarInput${s}`,
      }));

      const obj = {
        ApproveNumber: this.passNumber,
        Content: [...seqNosArray],
      };

      try {
        await window.axios.put(url, obj);
        this.$nextTick(() => {
          refKeyArray.forEach((s, i) => {
            this.$refs[`${s.refKey}`][0].currentTime = this.$moment(
              new Date()
            ).format("YYYY-MM-DD");
          });
        });
        this.$bvToast.toast("批次核定成功", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });

        await this.getData();

        this.$bvModal.hide("editMultilpleSchoolModal");
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openPrintPage(item) {
      const url = `/#/doc24-2-1?schoolcode=${item.SchoolCode}&byear=${item.BYear}&term=${item.Term}&category=S`;
      window.open(url);
    },
    openPrintPage2(item) {
      const url = `/#/doc15?schoolcode=${item.SchoolCode}&byear=${item.BYear}&term=${item.Term}&category=S`;
      window.open(url);
    },
    openEquipPurchase(item) {
      store.nowTab = 7;
      store.semester = this.searchSemester;
      store.editItem = JSON.parse(JSON.stringify(item));
      store.nowTableTitle = "採購品目申請明細表";

      this.$bvModal.show("equipmentLocalPurchases");
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchSchool = null;
      this.searchYear = new Date().getFullYear() - 1911 - 1;
      this.searchSemester = 1;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    async getData() {
      // odata3 qs top&skip
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      let orderBy = this.orderBy;
      !orderBy.length ? orderBy.push("Status desc") : "";
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      const account = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");

      let url = "";
      url = `BudgetApply/List${qs}&actor=${account}&sBYear=${this.searchYear}&sTerm=${this.searchSemester}&sCategory=S`;
      if (this.searchSchool) {
        url = `${url}&schoolcode=${this.searchSchool}`;
      }

      try {
        let { Items, Count } = await window.axios.get(url, {
          params: { sCategory: "S", sStatus: [4, 6, 7] },
        });
        Items = Items.map((s) => {
          s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "";
          s.applyDate = s.ApplyDate ? new Date(`${s.ApplyDate}`) : "";
          s.approved = false;
          s.Subsidy_Amt = 0;
          if (s.budgetings.length > 0) {
            s.budgetings.forEach((b) => {
              s.Subsidy_Amt += b.Subtotal;
            });
          }
          return s;
        });
        this.items = JSON.parse(JSON.stringify(Items));
        this.compontKey += 1;

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    const currentMonth = new Date().getMonth() + 1;
    if(currentMonth >= 1 && currentMonth <= 6){
      this.searchSemester = 2;
    }else{
      this.searchSemester = 1;
    }
    this.compontKey = 0;
    await this.getAllSchool();
    await this.getData();
  },

  watch: {
    passNumber(v) {
      let value = `${v}`;
      if (v.length > 9) {
        value = value.substring(1, 10);
      }
      const result = value;
      this.$nextTick(() => (this.passNumber = result));
    },
    passOneSchoolApprovedNumber(v) {
      let value = `${v}`;
      if (v.length > 9) {
        value = value.substring(1, 10);
      }
      const result = value;
      this.$nextTick(() => (this.passOneSchoolApprovedNumber = result));
    },
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 60px 150px 130px 140px 140px 160px 180px 120px 120px 140px 140px 140px 140px 160px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 40px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px 220px 300px repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
::v-deep#editMultilpleSchoolModal___BV_modal_header_ {
  color: #fff;
}
</style>
